import React from "react";
import LightGallery from "lightgallery/react";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import { memo } from "react";
const Outputimage
 = ({ imageurl , onInit }) => {

  return (
    <LightGallery  onInit={onInit} speed={500} plugins={[lgThumbnail, lgZoom]}>
            
    {
      imageurl&&
      <a
      href={imageurl}
      data-lg-size="1406-1290"
      style={{ marginRight: 20,width:"100%" }}
    >
      <img
        className="myImages"
        style={{ width: "100%", height: "100%",borderRadius:"10%" }}
        alt="img1"
        src={imageurl}
      />
    </a>
    }
 </LightGallery>
 
 );
};

export default memo(Outputimage);
