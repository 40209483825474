import React, { useEffect } from "react";
import LightGallery from "lightgallery/react";

import { memo } from "react";

// If you want you can use SCSS instead of css

// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

const ImagesSlider = ({ file,onInit }) => {
 

  return (
    <div style={{ width: "70%"}}>

      
           {file.length > 0 &&<> <h2 style={{ fontFamily: "Montserrat" }} className="heading">Queued</h2>     <LightGallery onInit={onInit} speed={500} plugins={[lgThumbnail, lgZoom]}>
     {
          file.map((obj,i) => {
            return (
              <a
              className="gallery2"
                href={URL.createObjectURL(obj)}
                data-lg-size="1406-1390"
                style={{ marginRight: 20 }}
              >
                <img
                  className="myImages"
                  
                  alt={i}
                  src={URL.createObjectURL(obj)}
                />
              </a>
            );
          })
        }
      </LightGallery>
      </>

}
</div>
  );
};

export default memo(ImagesSlider);
